//@import './bootstrap/stylesheets/bootstrap';

body {
  padding-top: 80px;
}


/* globals */

.m-r {
  margin-right: 1em;
}
.m-t-0 {
  margin-top: 0;
}

.row.eh {
  display: flex;
  flex-wrap: wrap;
}


/* specific */

._react-file-reader-input {
  display: inline-block;
}

.form-robotForm {
  .input-group .form-control {
    padding-right: 2px;
  }
  .input-group .form-control + .input-group-addon {
    padding-left: 0;
  }
  .input-name {
    display: inline-block;
    width: 300px;
  }
  .form-control:read-only,
  .form-control:read-only + .input-group-addon {
    border: none;
    box-shadow: none;

    &.input-name {
      background-color: transparent;
    }
  }
  table {
    th, td {
      text-align: center;
    }
  }
}

.view-robotView {
  .availableRobots {
    .availableRobot {
      cursor: pointer;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 15px;
      height: 100%;

      &:hover {
        background-color: #f5f5f5;
      }

      display: flex;
      flex-direction: column;

      .img-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 60%;
        }
      }
    }
  }
}

#gui {
  position: absolute;
  right: 0;
}